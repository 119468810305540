





















import { ThemeConfig } from '@/temas';
import {Vue,Component,Prop} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
const namespaceModulo:string ='sistemaModule';
@Component({
    name : 'Card'
})
export default class BaseCard extends Vue
{
        @Prop({type:String,default:'white'})
        color!:string;
        @Prop({type:String,default:''})
        cardClass!:string;
        @Prop({type:String,default:''})
        padding!:string;

        @Getter('getThemeMode',{namespace:namespaceModulo}) getThemeMode!:ThemeConfig;
    
}
