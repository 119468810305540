






















































import {Vue,Component} from 'vue-property-decorator';
import BaseCard from '@/components/Base/Card.vue';
import {Action,State} from 'vuex-class';
import {mainAxios} from '@/plugins/axios';
import {listadoSistema, SistemaModal} from '@/entidades/Sistema/SitemaModal';
//import firebase from 'firebase';
import Loading from '@/entidades/Sistema/Loading';
//var message = firebase.messaging();
var namespace = "authModule";
@Component({
    components:{
        BaseCard
    }
})
export default class LoginView extends Vue
{
    show:boolean =false;
    sistemaId:number =1;
    userName:string ="";
    password:string ="";
    checkbox1:boolean= true;
    checkbox2:boolean= false;
    listadoSistema:Array<SistemaModal> = [];
    @Action('loginUser',{namespace})login!:Function;
    @State('error',{namespace}) error!:boolean;
    @State('errorMessage',{namespace}) errorMessage!:string;
    @State('usuarioId',{namespace}) usuarioId!:string;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;

    async loginUser()
    {
        try
        {
            this.changeLoading(new Loading(true,"Por favor espere....Estamos configurando lo necesario, para usar el sistema"));
            let usuario = {
                'sistemaId'  : this.sistemaId,
                'userName'   : this.userName,
                'password'   :this.password,
                'versionApp' : 'sinVersion'
            }
            let response:boolean = await this.login(usuario);
            if(response == true)
            {

                var complement =  this.sistemaId == 2 ? "KTB" : this.sistemaId == 3 ? "MADERAVERDE" : "ADMINISTRACIONPAGO";
                if(this.sistemaId === 1)
                {
                    this.$router.push({name : 'HomePrincipalForestal'})
                }
                else 
                {
                    this.$router.replace({name:complement});
                }
                
            }
        }
        catch(error)
        {

        }
        finally
        {
                this.changeLoading(new Loading(false,""));
        }

    }

    obtenerSistema()
    {
        this.listadoSistema = listadoSistema();
    }

    mounted() {
        this.obtenerSistema();
    }
}
